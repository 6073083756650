import { useStaticQuery, graphql } from "gatsby"

export const useCareerImages = () => {
  const data = useStaticQuery(graphql`
    {
      medstaff: file(relativePath: { eq: "icons/medstaff.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      intern: file(relativePath: { eq: "icons/internship.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      hq: file(relativePath: { eq: "icons/hq.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return data
}
