export const careerRoleOptions = [
  {
    name: "hq",
    label: "Head Office",
    value: "shrSk89TWmi2okbKw",
    description: "Head Office",
    logo: "hq",
  },
  {
    name: "medstaff",
    label: "Medical Staff",
    value: "shrSrd1yon2PJF4Ec",
    description: "Medical Staff",
    logo: "medstaff",
  },
  {
    name: "intern",
    label: "Intern",
    value: "shrjpdv53rCbJqV6M",
    description: "Intern",
    logo: "intern",
  },
]
